import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import ConfirmPopup from "../../components/ui/modals/ConfirmPopup";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import serviceDataMapper from "../../utils/service-data-mapper";
import {
  saveActionTypes,
  GlobalOpsServiceType
} from "../../constants/search.constants";

const EditTopServicePage = props => {
  const { service, updateParentState, EditServiceModule } = props;
  const [apiTopService, setApiTopService] = useState(null);
  const { dispatch, state, ctxRequestPartsInventoryCommon } =
    useServiceSearchContext();
  const { localeStrings, debugQuote, vehicle, userPermissions } = state;
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const payTypes = state.payTypes;

  useEffect(() => {
    setApiTopService(service);
  }, [service]);

  const backToService = () => {
    updateParentState(false);
    dispatch({
      type: Actions.SET_SEARCH_HEADER,
      payload: true
    });
    setApiTopService(null);
  };

  const handleServiceUpdate = updatedService => {
    console.log("handle service update", updatedService);
    let newService = {};
    if (
      [
        GlobalOpsServiceType.DEALERCATALOG,
        GlobalOpsServiceType.DIAGNOSIS
      ].includes(updatedService.operationSource)
    ) {
      newService = serviceDataMapper.updateDealerPublished(
        apiTopService,
        updatedService
      );
    } else if (
      [GlobalOpsServiceType.GLOBALCATALOG].includes(
        updatedService.operationSource
      )
    ) {
      newService = serviceDataMapper.updateServiceGlobalRepair(
        apiTopService,
        updatedService
      );
    }
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVE
    });
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else backToService();
  };

  const saveServiceAndGoBack = updatedService => {
    let newService = {};
    if (
      [
        GlobalOpsServiceType.DEALERCATALOG,
        GlobalOpsServiceType.DIAGNOSIS
      ].includes(updatedService.operationSource)
    ) {
      newService = serviceDataMapper.updateDealerPublished(
        apiTopService,
        updatedService
      );
    } else if (
      [GlobalOpsServiceType.GLOBALCATALOG].includes(
        updatedService.operationSource
      )
    ) {
      newService = serviceDataMapper.updateServiceGlobalRepair(
        apiTopService,
        updatedService
      );
    }
    dispatch({
      type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
      payload: newService
    });
    dispatch({
      type: Actions.SET_SAVE_ACTION_TYPE,
      payload: saveActionTypes.SAVEANOTHER
    });
    backToService();
  };

  // @todo-paytype: trigger parent handler to pass paytype value
  const asyncUpdatePartsByPaytype = async (paytype, editedService) => {
    apiTopService.payTypeCode = paytype;
    ctxRequestPartsInventoryCommon(apiTopService, editedService);
  };

  // @todo-serviceType: trigger parent handler to pass paytype value
  const asyncUpdatePartsByServiceType = async (serviceType, editedService) => {
    apiTopService.serviceTypeCode = serviceType;
    ctxRequestPartsInventoryCommon(apiTopService, editedService);
  };

  const asyncUpdatePartsByServiceContract = async (
    serviceContract,
    editedService
  ) => {
    apiTopService.serviceContract = serviceContract;
    ctxRequestPartsInventoryCommon(apiTopService, editedService);
  };

  const clonedEditServiceModule = !isEmpty(apiTopService)
    ? React.cloneElement(EditServiceModule, {
        debugMode: debugQuote,
        testMode: true,
        userPermissions,
        vehicle,
        rawOperationDetails: apiTopService,
        service: serviceDataMapper.editModulePropsFromService(apiTopService),
        localeStrings,
        payTypes,
        partsPricingAndInventory: state.partsPricingAndInventory,
        onCancelHandler: handleCancel,
        onSaveHandler: handleServiceUpdate,
        onSaveAnotherHandler: saveServiceAndGoBack,
        // @todo: changePaytype triggers callback handler of ServiceSearchWrapper
        onChangePaytype: asyncUpdatePartsByPaytype,
        onChangeServiceType: asyncUpdatePartsByServiceType,
        onChangeServiceContract: asyncUpdatePartsByServiceContract,
        onServiceChange: handleServiceChange
      })
    : null;

  const editPage = !isEmpty(apiTopService) ? clonedEditServiceModule : "";

  const discardEditPopup = (
    <ConfirmPopup
      title={localeStrings["sq.search.common.alert_lbl"]}
      message={localeStrings["sq.search.common.leaving_edit_page"]}
      show={showDiscardEditPopup}
      okText={localeStrings["sq.search.common.proceed_button"]}
      cancelText={localeStrings["sq.search.common.cancel_button"]}
      okAction={backToService}
      cancelAction={() => setShowDiscardEditPopup(false)}
      hideCancel={false}
      hideOk={false}
      buttonStyle="danger"
    />
  );

  return (
    <>
      <div id="editTopService" className="search-flex-grid-container">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
        <div className="edit-page-wrapper">{editPage}</div>
      </div>
      {discardEditPopup}
    </>
  );
};

export default EditTopServicePage;

EditTopServicePage.defaultProps = {
  service: null,
  updateParentState: () => {},
  EditServiceModule: null
};
EditTopServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  updateParentState: PropTypes.func.isRequired,
  EditServiceModule: PropTypes.element
};
