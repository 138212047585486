/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import React, { useContext, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import { AppContext } from "../../../state/app-context";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import { writeLogEntry } from "../../../services/log.service";
// @import custom components
import ServiceSearchModule from "../../../ServiceSearchModule";
import EditServiceModule from "../../../EditServiceModule";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
// @import services
import axiosService from "../../../api/xmmAxios";
import quoteService from "../../quote-summary/services/quote.service";
import topServicesService from "../services/top-services.service";
import globalOperationsService from "../services/global-operations.service";
import declinedServicesService from "../services/declined.service";
import dealerTireService from "../services/dealer-tire.service";
import servicePointsService from "../services/service-points.service";
import csrService from "../../quote-summary/services/csr.service";
// @import utils
import serviceCache from "../../utils/service-cache.util";
import { getDealerTireParams } from "../utils/dealer-tire.util";
import {
  fixDealerTotalFlatPricing,
  getPayloadForPartsPricingAndInventory
} from "../utils/data-util";
import * as gtmEvent from "../../utils/gtag/gtag-event.util";
import { findMandatoryCatalogFees } from "../utils/quote-util";
import {
  GlobalOpsServiceType,
  SERVICE_SUMMARY
} from "../../../constants/pages.constants";
import {
  DealerPublishedCategory,
  QuoteServiceTypes
} from "../constants/page-wrapper.constants";
// @import css
import "./SearchServiceWrapper.scss";
import appServices from "../../../services/app.service";
import { payTypeCodes } from "../../../constants/quote-status.constants";
import { appTypes } from "../../../constants/app.constants";
import { YES } from "../../repair-order/constants/adjustment.constant";

function SearchServiceWrapper() {
  const appContext = useContext(AppContext);
  const [servicesBundle, setServicesBundle] = useState({});
  const [dealerTireParams, setDealerTireParams] = useState({});
  const { localeStrings, synonymsList } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const { customer, vehicle, quoteSummary, makeVariantMap } = state;
  const { confirmationId, quoteServices } = quoteSummary;
  const { dealer } = appContext;
  const commonConsumerId =
    isEmpty(customer) || isNull(customer.commonConsumerId)
      ? ""
      : customer.commonConsumerId;
  const payTypes = state.payTypes;
  const serviceTypesList = state.serviceTypes;
  const vendorList = state.vendorList;
  const serviceContracts = state.serviceContracts;
  const payTypeSubTypes = state.payTypeSubTypes;
  const costAllocationTypes = state.costAllocationTypes;

  useComponentDidMount(() => {
    axiosService.setupAxios(appContext);
    loadServicesBundle();
    // @dealer-tire: check if it is right place
    setDealerTireParams(getDealerTireParams(appContext));
  });
  const loadServicesBundle = async () => {
    const personId = get(customer, "personId", "");
    let drivingCondition = "Normal";
    if (!isEmpty(vehicle)) {
      if (vehicle.drivingConditions && vehicle.drivingConditions.length === 1) {
        drivingCondition = vehicle.drivingConditions[0].value;
      } else if (
        vehicle.drivingConditions &&
        vehicle.drivingConditions.length > 1
      ) {
        drivingCondition = !vehicle.defaultDrivingCondition
          ? "Normal"
          : vehicle.defaultDrivingCondition;
      }
    }

    const [
      servicePointsResponse,
      globalOperationsResponse,
      topServicesResponse,
      declinedAndRecallServicesResponse
    ] = await Promise.allSettled([
      servicePointsService.getServicePoints(
        dealer.dealerCode,
        vehicle,
        vehicle.mileage,
        drivingCondition,
        appContext.locale,
        0
      ),
      globalOperationsService.getGlobalOperations(vehicle),
      topServicesService.getTopServices(vehicle),
      declinedServicesService.getDeclinedAndRecallServices({
        vehicle,
        config: appContext,
        quoteConfirmationId: confirmationId,
        personId
      })
    ]);
    // @todo use a Reducer instead of useState. Reducers improves handling more complex objects, whereas useState fits better for primitive values
    setServicesBundle({
      servicePoints:
        servicePointsResponse.status === "fulfilled"
          ? servicePointsResponse.value
          : [],
      globalOperations:
        globalOperationsResponse.status === "fulfilled"
          ? globalOperationsResponse.value
          : [],
      topServices:
        topServicesResponse.status === "fulfilled"
          ? topServicesResponse.value
          : [],
      declinedAndRecallServices:
        declinedAndRecallServicesResponse.status === "fulfilled"
          ? declinedAndRecallServicesResponse.value
          : {}
    });
  };
  // BUGFIX- workaround - extra fields (labor,labors,parts) added at quoteServices(menu) to show total labor, total parts for menu in summary page;
  // these extra fields to be removed before updating quote payload for existing quote
  const cleanExtraFieldsForQuoteService = quoteSummary => {
    const { quoteServices } = quoteSummary;
    if (!isEmpty(quoteServices)) {
      quoteServices.map(service => {
        if (service.quoteServiceType === QuoteServiceTypes.MENU) {
          delete service.parts;
          delete service.labors;
          delete service.labor;
        }
        return service;
      });
    }
  };
  // Local handler to enable masking effect by updating newQuoteContext state prop
  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  /**
   * Adds mandatory fees to a new service when applicable.
   * @param {object} quoteSummary Current quote object. Price adjustments will be made to this object when applicable.
   * @param {object} selectedService New service being added. Fees will be added to this object when applicable, and price adjustments made.
   */
  const handleMandatoryCatalogFees = (quoteSummary, selectedService) => {
    try {
      // RO mandatory fees get handled on the backend, as part of the tax calculations.
      // Quote mandatory fees get handled here.
      if (appContext?.appType === "SQ") {
        if (!selectedService.catalogFees?.length) {
          const operationDetails = JSON.parse(
            selectedService.quoteRawService?.rawService ?? "null"
          );
          selectedService.catalogFees = findMandatoryCatalogFees(
            operationDetails,
            selectedService.payTypeCode,
            selectedService.finalLaborPrice
          );
        }
        const mandatoryFeesTotal = sumBy(
          selectedService.catalogFees,
          "appliedFee"
        );
        if (selectedService.lineServicePrice) {
          selectedService.lineServicePrice += mandatoryFeesTotal;
        } else {
          selectedService.servicePrice += mandatoryFeesTotal;
        }
        quoteSummary.subtotalPrice += mandatoryFeesTotal;
        quoteSummary.totalPrice += mandatoryFeesTotal;
      }
    } catch (e) {
      console.error("Error in handleMandatoryCatalogFees()", e);
    }
  };

  const showChargeAccountInfo = async quoteSummary => {
    const hasCustomerPayer = quoteSummary?.payers?.some(
      payer => payer.payType === payTypeCodes.CUSTOMER
    );

    const isVehicleHasStockNumber = isEmpty(quoteSummary?.vehicle?.stockNumber);
    let idForChargeAccount = null;
    if (!isEmpty(customer?.firstName) && !isEmpty(customer?.lastName))
      idForChargeAccount = customer?.commonConsumerId;
    if (isEmpty(customer?.firstName)) idForChargeAccount = customer?.extId;

    const isChargeAccountFeatureEnabled =
      appContext?.appType === appTypes.SQ &&
      appContext?.dealerProperties?.ENABLE_CSR_APP === YES;

    if (
      isChargeAccountFeatureEnabled &&
      !isEmpty(idForChargeAccount) &&
      !isEmpty(quoteSummary?.confirmationId) &&
      hasCustomerPayer &&
      isVehicleHasStockNumber
    ) {
      try {
        const chargeAccountResponse = await appServices.getChargeAccountInfo(
          dealer.dealerCode,
          idForChargeAccount
        );
        if (chargeAccountResponse) {
          dispatch({
            type: Actions.SET_CUSTOMER_CHARGE_ACCOUNT_INFO,
            payload: chargeAccountResponse
          });
        }
      } catch (e) {
        dispatch({
          type: Actions.SET_CUSTOMER_CHARGE_ACCOUNT_INFO,
          payload: null
        });
      }
    }
  };

  const handleOnSelectedService = async (
    selectedService,
    serviceType = "DEALER_PUB_MAINT_OPS",
    actionType
  ) => {
    const { confirmationId, quoteId } = quoteSummary;
    const quoteAction =
      isEmpty(confirmationId) && !quoteId ? "CREATE" : "UPDATE";
    showPageLoading(true);
    handleMandatoryCatalogFees(quoteSummary, selectedService);
    try {
      cleanExtraFieldsForQuoteService(quoteSummary);
      console.log("handleOnSelectedService", serviceType, selectedService);
      let response = null;
      let quoteResponse = null;
      const createUpdatePayload = {
        appContext,
        customer,
        vehicle,
        selectedService,
        quoteSummary
      };

      if (quoteAction === "CREATE") {
        if (serviceType === QuoteServiceTypes.MENU) {
          response = await quoteService.createQuoteWithMenuPackage(
            createUpdatePayload
          );
        } else if (
          serviceType === QuoteServiceTypes.DECLINED ||
          serviceType === QuoteServiceTypes.RECALL
        ) {
          response = await quoteService.createQuote(createUpdatePayload);
        } else if (
          serviceType === QuoteServiceTypes.DEALER_PUB_MAINT_OPS ||
          serviceType === QuoteServiceTypes.GLOBAL_REPAIR_OPS ||
          serviceType === QuoteServiceTypes.DEALER_PUB_REPAIR_OPS
        ) {
          response = await quoteService.createQuoteForGlobalOps(
            createUpdatePayload
          );
        }
      } else if (quoteAction === "UPDATE") {
        if (serviceType === QuoteServiceTypes.MENU) {
          response = await quoteService.updateQuoteWithMenuPackage(
            createUpdatePayload
          );
        } else if (
          serviceType === QuoteServiceTypes.DECLINED ||
          serviceType === QuoteServiceTypes.RECALL
        ) {
          response = await quoteService.updateQuote(
            createUpdatePayload,
            serviceType
          );
        } else if (
          serviceType === QuoteServiceTypes.DEALER_PUB_MAINT_OPS ||
          serviceType === QuoteServiceTypes.GLOBAL_REPAIR_OPS ||
          serviceType === QuoteServiceTypes.DEALER_PUB_REPAIR_OPS
        ) {
          response = await quoteService.updateQuoteForGlobalOps(
            createUpdatePayload
          );
        }
      }

      if (!isEmpty(response)) {
        const { quoteId, confirmationId } = response;
        if (!confirmationId && !quoteId) {
          quoteResponse = {
            quoteId: null,
            confirmationId: null,
            quoteServices: [],
            message: localeStrings["sq.newquote.summary.error_adding_service"]
          };
        } else {
          if (isEmpty(response.quoteServices)) response.quoteServices = [];
          response.message = "";
          quoteResponse = Object.assign({}, response);
        }

        if (quoteAction === "CREATE") {
          dispatch({
            type: Actions.SET_QUOTE,
            payload: quoteResponse
          });
          showChargeAccountInfo(quoteResponse);
        } else if (quoteAction === "UPDATE") {
          if (
            appContext?.appType === "CSR" &&
            selectedService?.payTypeCode === "W" &&
            serviceType !== QuoteServiceTypes.MENU
          ) {
            try {
              const quoteServiceFromResponse =
                quoteResponse?.quoteServices?.find(
                  service =>
                    parseInt(service?.extServiceId, 10) === selectedService?.id
                );
              console.log("quoteService", quoteServiceFromResponse);
              const warrantyDetailsPayload = selectedService?.warranty;
              const warrantyResponse = await csrService.addWarrantyToService(
                appContext,
                quoteServiceFromResponse,
                warrantyDetailsPayload
              );

              console.log("warrantyResponse", warrantyResponse);
              const serviceIndex = quoteResponse?.quoteServices?.findIndex(
                service =>
                  parseInt(service?.quoteServiceId, 10) ===
                  quoteServiceFromResponse?.quoteServiceId
              );

              quoteResponse.quoteServices[serviceIndex] = {
                ...quoteServiceFromResponse,
                warranty: warrantyResponse
              };
            } catch (error) {
              console.log("error", error);
              if (appContext?.appType === "CSR") {
                dispatch({
                  type: Actions.SET_PAGE_MASK,
                  payload: false
                });
              }
            }
          }

          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: quoteResponse
          });
        }

        switch (serviceType) {
          case "RECALL":
            serviceCache.addAddedRecallServices(
              confirmationId,
              selectedService
            );
            break;
          default:
            break;
        }
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
      dispatch({
        type: Actions.CLEAR_QUOTE
      });
    } finally {
      showPageLoading(false);
      // NOTE: Read argu {actionType === "SAVE"} passed to this handler, then redirect to summary page
      if (!isEmpty(actionType) && actionType === "SAVE") {
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: SERVICE_SUMMARY
        });
      }
    }
  };
  // @todo-newcallback for menus: add this handler in search module files
  const handleGetPartsInventoryCommon = async (service, payTypeCode, cb) => {
    let partsPricingAndInventory = [];
    const { vehicle } = state;
    try {
      const postParams = getPayloadForPartsPricingAndInventory(
        service,
        vehicle,
        payTypeCode,
        service.operationSource,
        commonConsumerId,
        service.serviceTypeCode || ""
      );
      partsPricingAndInventory =
        await globalOperationsService.getPartsPricingAndInventory(
          null,
          postParams
        );
    } catch (error) {
      partsPricingAndInventory = [];
      console.log("Call to opentrack API failed with error", error);
    }
    console.log(
      "opentrack api handleGetPartsInventoryCommon",
      partsPricingAndInventory
    );
    cb(partsPricingAndInventory); // this object passed to serviceSerachModule
  };

  // @workaround: second callback to fetch partsInventory from opentrack api; either return partsInventory or globalOperation
  const handleGetPartsPricingAndInventory = async (
    params,
    globalOperationDetails,
    callbackFn
  ) => {
    let partsPricingAndInventory = [];
    const { vehicle } = state;
    if (!isEmpty(globalOperationDetails)) {
      const payTypeCode = getDefaultPayTypeCode(
        globalOperationDetails.serviceKind,
        globalOperationDetails.defaultPayTypeCode
      );
      const serviceTypeCode = getDefaultServiceType(
        globalOperationDetails.defaultServiceType
      );
      const { operationSource, operationId } = params;
      const postParams = {
        make: vehicle.make,
        appSource: "ServiceQuoting",
        operationId,
        operationSource,
        vin: vehicle.vin,
        opCode: globalOperationDetails.opCode || "",
        payType: payTypeCode || "",
        serviceType: serviceTypeCode || "",
        commonConsumerId: commonConsumerId || "",
        partNumbers: []
      };
      try {
        partsPricingAndInventory =
          await globalOperationsService.getPartsPricingAndInventory(
            globalOperationDetails,
            postParams
          );
      } catch (error) {
        partsPricingAndInventory = [];
        console.log("Call to opentrack API failed with error", error);
      }
    }
    console.log(
      "opentrack handleGetPartsPricingAndInventory",
      partsPricingAndInventory
    );
    callbackFn(partsPricingAndInventory); // this object passed to serviceSerachModule
  };
  // util to return default paytype parse thru unified response
  const getDefaultPayTypeCode = (operationSource, payType) => {
    const tempPayType = !payType ? "" : payType;
    let payTypeCode =
      operationSource === "RECALL"
        ? "W"
        : operationSource === "DECLINED"
        ? "C"
        : tempPayType;
    if (!isEmpty(vehicle?.stockNumber)) {
      payTypeCode = "I";
    }
    return payTypeCode;
  };
  // util to return default serviceType parse thru unified response
  const getDefaultServiceType = serviceType => {
    return serviceType ? serviceType : "";
  };
  // @note: callback handler to fetch glboal operation and pass to editservice module
  const handleOnGetOperationDetails = async (params, cb) => {
    const { vehicle } = state;
    const globalOperationDetails =
      await globalOperationsService.getGlobalOperationDetails(vehicle, params);
    // set totalPriceOverridden=true if priceSource=DEALER_TOTAL_FLAT_PRICING and totalPriceOverridden=false so that
    // the total price would show up as overridden total price
    fixDealerTotalFlatPricing(globalOperationDetails);
    // @note: we make open track api after this callback returned update globalOperationDetails, to fetch object partsPricingAndInventory
    cb(globalOperationDetails);
  };
  const handleDealerTireConfig = async cb => {
    const { appEnv, appSource } = appContext;
    const dealerTireAuth = await dealerTireService.getDealerTireHandle(
      dealer.dealerCode,
      appEnv,
      appSource
    );
    cb(dealerTireAuth);
  };
  const handleOnGetServicePoints = async (params, cb) => {
    const { drivingCondition, locale, mileage, useExactMileage } = params;
    const { vehicle } = state;
    const servicePoints = await servicePointsService.getServicePoints(
      dealer.dealerCode,
      vehicle,
      mileage,
      drivingCondition,
      locale,
      useExactMileage
    );
    cb(servicePoints);
  };
  const handleOnEditService = isEditing => {
    dispatch({
      type: Actions.SET_IS_EDITING_SERVICE,
      payload: isEditing
    });
  };

  const handleGoBackToCSRQuoteSummary = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: SERVICE_SUMMARY
    });
  };
  const hasMenuQuickFilterAccess =
    appContext.appType !== "CSR"
      ? true
      : appContext.dealerProperties?.FULL_SCHEDULE_ENABLED === "Y";
  const loadMask = <LoadingIndicator htmlId="quoteBodyMasking" size="small" />;
  return !isEmpty(servicesBundle) ? (
    <ServiceSearchModule
      // @module element: pass common/default props here to read directly in EditServiceModule file
      EditServiceModule={
        <EditServiceModule
          localeStrings={localeStrings}
          commonConsumerId={commonConsumerId}
          // @csr-logic
          appType={appContext.appType}
          // @Exceptional case: axios instance passed to call rest api's inside partsLookup or EditService modules for dynamic data callback
          axiosInstance={axiosService}
          debugMode={appContext.isDebug}
          writeLogEntry={writeLogEntry}
          userPermissions={appContext.userPermissions}
          dealerCode={dealer.dealerCode}
          payTypeSubTypes={payTypeSubTypes}
          costAllocationTypes={costAllocationTypes}
          confirmationId={confirmationId}
          serviceTypes={serviceTypesList}
          vendorList={vendorList}
          serviceContracts={serviceContracts}
          dealerProperties={appContext.dealerProperties}
        />
      }
      // @required props
      locale={appContext.locale}
      dealerTireParams={dealerTireParams}
      makeVariantMap={makeVariantMap}
      payTypes={payTypes}
      serviceTypes={serviceTypesList}
      vendorList={vendorList}
      serviceContracts={serviceContracts}
      payTypeSubTypes={payTypeSubTypes}
      costAllocationTypes={costAllocationTypes}
      vehicle={vehicle}
      synonymsList={synonymsList}
      // @note: Donot add fields which will be updated multiple times in config
      config={{
        locale: appContext.locale,
        isDebug: appContext.isDebug,
        appType: appContext.appType, // @csr-logic
        appSource: appContext.appSource, // @csr-logic
        userPermissions: appContext.userPermissions,
        isDealerTireEnabled: appContext.isDealerTireEnabled,
        webKey: appContext.webKey,
        dealer: {
          dealerCode: dealer.dealerCode,
          dmsType: dealer.dmsType
        },
        confirmationId,
        schemaName: appContext.schemaName,
        user: appContext.user,
        customOperationPage: false,
        quickFilterAccess: {
          topServices: true,
          menu: hasMenuQuickFilterAccess,
          diagnosis: true,
          declined: true,
          recall: true,
          tires: true
        },
        editModuleAccess: true,
        showPrice: true,
        showOpcode: false,
        showQuickFilters: true,
        dealerProperties: appContext.dealerProperties
      }}
      // function as a prop for go back to summary screen for quote from search service
      handleGoBackToCSRQuoteSummary={handleGoBackToCSRQuoteSummary}
      // @data props are optional to pass depends on requirement
      quoteServices={quoteServices}
      servicePoints={servicesBundle.servicePoints}
      globalOperations={servicesBundle.globalOperations}
      topServices={servicesBundle.topServices}
      declinedAndRecallServices={servicesBundle.declinedAndRecallServices}
      gtmEvent={gtmEvent}
      // @callback restAPI handlers to fetch details
      onGetOperationDetails={handleOnGetOperationDetails}
      onGetDealerTireConfig={handleDealerTireConfig}
      onGetServicePoints={handleOnGetServicePoints}
      onGetPartsPricingAndInventory={handleGetPartsPricingAndInventory}
      onGetPartsInventoryCommon={handleGetPartsInventoryCommon}
      // @event handlers
      onSelectedMenu={(menu, actionType) =>
        handleOnSelectedService(menu, QuoteServiceTypes.MENU, actionType)
      }
      onSelectedDeclinedService={(service, actionType) =>
        handleOnSelectedService(service, QuoteServiceTypes.DECLINED, actionType)
      }
      onSelectedGlobalOpsService={(service, actionType) => {
        if (service.operationSource === GlobalOpsServiceType.GLOBALCATALOG) {
          handleOnSelectedService(
            service,
            QuoteServiceTypes.GLOBAL_REPAIR_OPS,
            actionType
          );
        } else if (
          service.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
          service.serviceKind === DealerPublishedCategory.MAINTENANCE
        ) {
          handleOnSelectedService(
            service,
            QuoteServiceTypes.DEALER_PUB_MAINT_OPS,
            actionType
          );
        } else if (
          service.operationSource === GlobalOpsServiceType.DEALERCATALOG &&
          service.serviceKind === DealerPublishedCategory.REPAIR
        ) {
          handleOnSelectedService(
            service,
            QuoteServiceTypes.DEALER_PUB_REPAIR_OPS,
            actionType
          );
        }
      }}
      onSelectedRecallService={(service, actionType) =>
        handleOnSelectedService(service, QuoteServiceTypes.RECALL, actionType)
      }
      onEditService={isEditing => {
        handleOnEditService(isEditing);
      }}
    />
  ) : (
    loadMask
  );
}

export default SearchServiceWrapper;
/* eslint-enable no-console */
