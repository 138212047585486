import React, { useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import Button from "@cx/ui/Button";
import TruncateText from "@cx/ui/TruncateText";
import Badge from "@cx/ui/Badge";
import {
  isQuoteStatusWorkFinishedOrLater,
  priceValueFormatter
} from "../utils/format";
import { convertMinutesToHours, extractNotes } from "../utils/data-transformer";
import { doesEmpty } from "../../utils/object";
import { getDMSPartsPricingForService } from "./services/service-details.service";
import { AppContext } from "../../state/app-context";
import axiosService from "../../api/xmmAxios";
import { Actions, useNewQuoteContext } from "../../state/NewQuoteContext";
import { OperationSources } from "../page-wrapper/constants/page-wrapper.constants";
import { getPartAvailabilityLabel } from "../../PartsLookupModule/utils/helper.util";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { appTypes } from "../../constants/app.constants";
import csrService from "./services/csr.service";
import { PartsLookupModule } from "../../PartsLookupModule";
import globalOperationsService from "../page-wrapper/services/global-operations.service";
import { formatMenuServiceToRawOpeartionDetails } from "../page-wrapper/utils/parts-service-format.util";
import modifyPartsService from "./services/modify-parts.service";
import { loadQuote } from "../page-wrapper/services/quote-api.service";
import {
  roStatusOptions,
  partStatus,
  paymentStatus,
  payTypeCodes,
  warrantySubmissionStates,
  invoiceBadgeLabels,
  serviceLineBadgeLabels,
  warrantyBadgeLabels,
  badgeColors,
  warrantyBadgeLabelsNew
} from "../../constants/quote-status.constants";
import ServiceDetailsSection from "../repair-order/components/service-details-section/service-details-section.component";
import { getUniqueTechs } from "../../utils/quote.util";

const MenuService = props => {
  const appContext = useContext(AppContext);
  const { dispatch, state, blockUntilCompleted } = useNewQuoteContext();
  const {
    service,
    keyCompId,
    localeStrings,
    UnitOfMeasureMap,
    totalPriceOverridden,
    totalPriceOverride,
    vehicle,
    renderModifyLink,
    expandDetails
  } = props;
  const {
    isPartsView,
    userPermissions,
    user: { quoteUserId, id: currentUserId },
    appType,
    debugMode,
    dealerProperties
  } = appContext;
  const { customer, quoteSummary, specialOrderPriorities } = state;
  const { labor, parts, finalPartsPrice, calculatedTotalPartsPrice } = service;
  const clsLabor = labor ? "quote-labor no-border" : "hide";
  const isCSR = appContext.appType === appTypes.CSR;
  const isWithAdvisor = isCSR && quoteSummary.quoteStatus === "WITH_ADVISOR";
  const isFinalized = isCSR && quoteSummary.quoteStatus === "FINALIZED";
  const isLaborExist = service.hasOwnProperty("labor");
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;

  const partsPrice = finalPartsPrice
    ? finalPartsPrice
    : calculatedTotalPartsPrice
    ? calculatedTotalPartsPrice
    : 0;
  // TODO: when CSR API ready for Menus, read it from service or NewQuoteContext state?
  // const techniciansList = [];
  let laborHtml = null;
  let partsContentHtml = null;
  let dealershipNotesHtml = null;
  let partsViewFooterHtml = null;
  // @note: verify labor{} from service and convert laborTime based on dealer catalog laborTimePrecision
  const laborObj = {};
  laborObj.catalogLabor = doesEmpty(service?.labor?.catalogLabor)
    ? null
    : service?.labor?.catalogLabor;
  laborObj.laborTime = doesEmpty(service?.labor?.laborTime)
    ? 0
    : Number(service?.labor?.laborTime);
  laborObj.laborRateOverride = doesEmpty(service?.labor?.laborRateOverride)
    ? null
    : service?.labor?.laborRateOverride;
  laborObj.laborRate = doesEmpty(service?.labor?.laborRate)
    ? null
    : service?.labor?.laborRate;
  // @note: labor price under menu service in summary  always read from service.finalLaborPrice instead of labor.laborPrice
  laborObj.laborPrice = service.finalLaborPrice;
  let laborPriceVal = "";
  let laborTimeVal = "";
  if (isLaborExist && !isPartsView) {
    if (!doesEmpty(laborObj.laborTime) && vehicle) {
      laborTimeVal = convertMinutesToHours(laborObj.laborTime);
    }
    laborPriceVal = doesEmpty(laborObj.laborPrice)
      ? ""
      : `${laborObj.laborPrice.toFixed(2)}`;
    laborHtml = (
      <div
        className={[clsLabor, "margin-left-12"].join(" ")}
        key={"labor-" + service.extServiceId}
      >
        <div className="labor-title">
          <span className="bold marginleft">
            {localeStrings["sq.newquote.summary.labor_lbl"]}{" "}
          </span>
          <span className="labor-time">- {laborTimeVal}</span>
          <span className="labor-hours">
            {localeStrings["sq.newquote.summary.hours_lbl"]}
          </span>
        </div>
        <div className="labor-price">{priceValueFormatter(laborPriceVal)}</div>
      </div>
    );
  }
  const dealershipNotes = extractNotes(service?.dealershipNotes || null);
  useEffect(() => {
    axiosService.setupAxios(appContext);
    const abortController = axiosService.newAbortController();
    async function fetchRealTimeParts() {
      service.operationSource = OperationSources.MENU;
      const parts = await getDMSPartsPricingForService({
        payType: service.payTypeCode,
        vehicle,
        service,
        commonConsumerId: customer?.commonConsumerId || "",
        abortSignal: abortController.signal
      });
      console.log("MenuService - fetchRealTimeParts", parts);
      // TODO - As per RO parts dashboard design, open track DMS API data send via this fetchRealTimeParts() to update cost,bin,shelf field for each menuservice.parts using action SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE?
      dispatch({
        type: Actions.SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE,
        payload: {
          extServiceId: service.extServiceId,
          parts: parts ?? []
        }
      });
    }
    fetchRealTimeParts();
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);
  // @csr-logic
  const hasUnapprovedParts = service?.parts?.some(p => !p.approver);
  const hasPartsWithOutUnitCost = service?.parts?.some(
    p => p?.unitCost === null
  );
  const handleApproveAllParts = async () => {
    await blockUntilCompleted(async () => {
      const partsToApprove = service.parts.filter(p => !p.approver);
      await Promise.all(
        partsToApprove.map(p => {
          return modifyPartsService.markPartAsApproved(
            appContext,
            quoteSummary,
            p.quoteServicePartId
          );
        })
      );
      const updatedQuote = await loadQuote({
        confirmationId: quoteSummary.confirmationId,
        localeStrings: appContext.localeStrings,
        dealerCode: appContext.dealer.dealerCode,
        appContext
      });
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: updatedQuote
      });
      await csrService.reAssignCounterPartsPerson(
        appContext,
        quoteSummary,
        currentUserId
      );
    });
  };
  const handleModifyParts = selectedMenuService => {
    // @note: In the case of menus, the complete menu record exists in the quote as a service.
    // It contains one or more menuServices, which is what we get as selectedService here.
    dispatch({
      type: Actions.SET_CURRENT_EDITING_MENU_SERVICE,
      payload: {
        menuService: selectedMenuService,
        menuRecord: props.quoteMenuRecord
      }
    });

    dispatch({
      type: Actions.SET_IS_MODIFY_PARTS,
      payload: true
    });
    dispatch({
      type: Actions.SET_QUOTE_MODIFIED,
      payload: true
    });
  };
  // @csr-logic
  const handleCoreReturn = async ({ serviceParts, totalPartsPrice }) => {
    // console.log(serviceParts, totalPartsPrice);
    const { menuPackage } = state;
    const isMenuPackage = service.operationSource === OperationSources.MENU;
    const updatePayload = {
      serviceParts,
      totalPartsPrice,
      quoteSummary,
      selectedService: service,
      quoteUserId,
      ...(isMenuPackage && { menuPackage })
    };
    let response;
    await blockUntilCompleted(async () => {
      if (isMenuPackage) {
        response = await modifyPartsService.updateQuoteFromModifyPartsWithMenu(
          updatePayload
        );
      }
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: response
      });
    });
  };

  const callbackPerformPartAction = async ({
    actionType,
    isEPChecked,
    isSOChecked,
    typeOfPurchase,
    quoteServicePartId,
    returnToInventory,
    serviceParts,
    totalPartsPrice
  }) => {
    await blockUntilCompleted(async () => {
      switch (actionType) {
        case "Approve":
          await modifyPartsService.markPartAsApproved(
            appContext,
            quoteSummary,
            quoteServicePartId
          );
          break;
        case "EmergencyPurchase":
          await modifyPartsService.updatePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            isEPChecked ? typeOfPurchase : ""
          );
          break;
        case "SpecialOrder":
          await modifyPartsService.updatePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            isSOChecked ? typeOfPurchase : ""
          );
          break;
        case "Remove":
          await modifyPartsService.deletePart(
            appContext,
            quoteSummary,
            quoteServicePartId,
            returnToInventory
          );
          break;
        case "CoreReturn":
          handleCoreReturn({ serviceParts, totalPartsPrice });
          break;
        default:
          break;
      }

      const updatedQuote = await loadQuote({
        confirmationId: quoteSummary.confirmationId,
        localeStrings: appContext.localeStrings,
        dealerCode: appContext.dealer.dealerCode,
        appContext
      });
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: updatedQuote
      });
      await csrService.reAssignCounterPartsPerson(
        appContext,
        quoteSummary,
        currentUserId
      );
    });
  };

  // @csr-logic
  const getPurchaseOrderDetails = async poNumber => {
    const response = await csrService.getPurchaseOrderDetailsCall(
      poNumber,
      appContext.dealer.dealerCode
    );

    return response;
  };

  if (!isEmpty(dealershipNotes)) {
    dealershipNotesHtml = (
      <div className="quote-comments no-border margin-left-12">
        <div className="comments-title">
          {localeStrings["sq.newquote.summary.dealership_notes_lbl"]}
        </div>
        <div className="comments-desc margin-left-12">{dealershipNotes}</div>
      </div>
    );
  }
  const partsHeaderHtml = !isPartsView ? (
    <div className="quote-labor no-border margin-left-12">
      <div className="parts-modify">
        <div className="labor-title bold marginleft">
          {" "}
          <span className="bold">
            {localeStrings["sq.newquote.summary.parts_lbl"]}
          </span>
          <span> ({parts.length})</span>
        </div>
        {!totalPriceOverride ? renderModifyLink(service) : null}
      </div>
      <div className="labor-price">
        {partsPrice >= 0 ? `${priceValueFormatter(partsPrice.toFixed(2))}` : ""}
      </div>
    </div>
  ) : null;
  // @csr-logic: PartsView condition for parts grid vs parts html table
  if (isCSR && isPartsView) {
    partsContentHtml = (
      <PartsLookupModule
        // @required props
        appType={appType}
        userPermissions={userPermissions}
        debugMode={debugMode}
        isPartsView={isPartsView}
        // @required- - expected values "EDIT_SERVICE" or "MODIFY_PARTS"
        actionType="EDIT_SUMMARY"
        // @required- make passed to support Make dropdown filter logic in Individual Part Tab
        make={!vehicle ? "" : vehicle.make}
        dealerProperties={appContext?.dealerProperties}
        dealerMakesProperty={
          appContext?.dealerProperties?.MANUFACTURER_MAKE_CODE_LIST
        }
        emergencyPartProperty={
          appContext?.dealerProperties?.ENABLE_DMSPLUS_EMERGENCY_PARTS
        }
        specialPartEnabled={appContext?.isCreateSpecialPartsEnabled}
        quoteSummary={quoteSummary}
        // @required prop - service object used to read service name, operationSource(global, non-global, menu, price override fields) for conditions.
        // Add service case - parts exist in serivce.parts(non-global);filteredParts(global);
        service={service}
        // @required- - All flows - pass parts of opentrack API callback response, to refresh recommended grid and selected parts grid as well
        // TODO - As per RO partsdashboard design, open track DMS API data send via this fetchRealTimeParts() to update cost,bin,shelf field for each menuservice.parts using action SET_IN_STOCK_PARTS_QUOTE_MENU_SERVICE?
        partsPricingAndInventory={[]}
        savedParts={service.parts}
        completedSteps={{ serviceOptions: true }}
        specialOrderPriorityList={specialOrderPriorities}
        onPartPricingLookup={async part => {
          const { customer, service } = state;
          return await globalOperationsService.getPricingAndInventoryForCustomPart(
            service,
            formatMenuServiceToRawOpeartionDetails(service),
            vehicle,
            customer,
            part
          );
        }}
        // @ERP Handler
        onPerformPartAction={callbackPerformPartAction}
        getPurchaseOrderDetails={getPurchaseOrderDetails}
        isCsrFinalized={isFinalized}
      />
    );
  } else {
    partsContentHtml = (
      <div className="parts-container margin-left-12">
        <div className="parts-box marginleft-service">
          {parts.map((partObj, index) => {
            const unitQty =
              partObj.partType === "fluid" && partObj.adjustedQuantity > 0
                ? UnitOfMeasureMap[partObj.adjustedUom]
                : "";
            const keyId = "part-" + partObj.extPartId + "-" + index;
            const partNumber = partObj.oemPartNumber || "";
            const showPartNumTip =
              partNumber && partNumber.trim().length > 10 ? true : false;
            const inStock = getPartAvailabilityLabel(partObj);

            return (
              <div className="parts-row" key={keyId}>
                <div className="part-desc">
                  {partObj.description || ""}
                  <span className="padding-left-4">
                    {partObj.oilType || ""}
                  </span>
                </div>
                <TruncateText
                  htmlId="partNumberText"
                  displayToolTip={showPartNumTip}
                >
                  <div className="part-number">{partNumber || ""} </div>
                </TruncateText>
                {partObj.hasOwnProperty("adjustedQuantity") ? (
                  <div className="qty">
                    {localeStrings["sq.newquote.summary.qty_lbl"]}:
                    <span className="padding-left-4">
                      {partObj.adjustedQuantity > 0
                        ? partObj.adjustedQuantity
                        : ""}
                    </span>
                    <span className="padding-left-2">{unitQty}</span>
                  </div>
                ) : (
                  <div className="qty" />
                )}
                {isCSR ? (
                  <div className="partStatus">
                    {csrService.buildPartStatusBadge(
                      partObj.approver
                        ? partStatus.APPROVED
                        : partStatus.REQUESTED
                    )}
                  </div>
                ) : null}
                {!totalPriceOverridden ? (
                  <div
                    className="unit-price"
                    style={{ textDecoration: "none" }}
                  >
                    {partObj.unitPrice >= 0
                      ? `${priceValueFormatter(partObj.unitPrice)}`
                      : ""}
                  </div>
                ) : null}
                {!partObj.dmsPending ? (
                  <div className={partObj.inStock ? "stock" : "no-stock"}>
                    {inStock}
                  </div>
                ) : null}
                {partObj.dmsPending ? (
                  <div className="loading">
                    <LoadingIndicator
                      htmlId={`part-${partObj.extPartId}`}
                      size="small"
                    />
                  </div>
                ) : null}
                {!totalPriceOverridden ? (
                  <div
                    className="part-price"
                    style={{ textDecoration: "none" }}
                  >
                    {partObj.partPrice >= 0
                      ? `${priceValueFormatter(partObj.partPrice)}`
                      : ""}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  let servicelineBadges = null;
  let serviceDetailsSection = null;
  const isServiceCompleted = !!service?.completedTime;
  if (isCSR && !isPartsView) {
    serviceDetailsSection =
      isServiceCompleted && expandDetails ? (
        <ServiceDetailsSection
          userPermissions={userPermissions}
          service={service}
        />
      ) : null;

    servicelineBadges = (
      <div className="service-details-section-badge">
        {isServiceCompleted ? (
          <Badge
            htmlId="serviceCompletedBadge"
            color={badgeColors.PURPLE}
            className="m-r"
          >
            {serviceLineBadgeLabels.COMPLETED}
          </Badge>
        ) : null}
        {isServiceCompleted &&
        quoteSummary?.quoteStatus === roStatusOptions.PRE_INVOICE ? (
          <Badge
            htmlId="readyForInvoicingBadge"
            color={
              service?.paymentStatus === paymentStatus.READY
                ? badgeColors.PURPLE
                : badgeColors.GRAY
            }
            className="m-r"
          >
            {service?.paymentStatus === paymentStatus.READY
              ? invoiceBadgeLabels.READY
              : invoiceBadgeLabels.PENDING}
          </Badge>
        ) : null}
        {service?.payTypeCode === payTypeCodes.WARRANTY &&
        isQuoteStatusWorkFinishedOrLater(quoteSummary.quoteStatus) ? (
          isUseOEMWarrantyEnabled ? (
            <Badge
              htmlId="warrantyReadyBadge"
              color={
                service?.warranty?.warrantySubmissionState ===
                warrantySubmissionStates.READY
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.READY
                ? warrantyBadgeLabelsNew.WARRANTY_READY
                : warrantyBadgeLabelsNew.PENDING_WARRANTY_REVIEW}
            </Badge>
          ) : (
            <Badge
              htmlId="warrantyReadyBadge"
              color={
                service?.warranty?.warrantySubmissionState ===
                warrantySubmissionStates.SUBMITTED
                  ? badgeColors.PURPLE
                  : badgeColors.GRAY
              }
              className="m-r"
            >
              {service?.warranty?.warrantySubmissionState ===
              warrantySubmissionStates.SUBMITTED
                ? warrantyBadgeLabels.SUBMITTED_TO_OEM
                : warrantyBadgeLabels.PENDING_SUBMISSION}
            </Badge>
          )
        ) : null}
      </div>
    );
  }
  const technicianNames = getUniqueTechs(service?.technicians);

  const technicianNamesHtml =
    isCSR && technicianNames?.length > 0 ? (
      <>Technician(s): {technicianNames.join(" | ")}</>
    ) : null;

  if (isCSR && isPartsView) {
    partsViewFooterHtml = (
      <div className="quote-action-links">
        <div className="quote-source-row">
          <div className="service-pay-type">
            {`Pay type: ${service?.payTypeCode || ""} | `}
            {technicianNamesHtml}
          </div>
        </div>
        {!isFinalized ? (
          <>
            <Button
              htmlId="modifyPartsButton"
              size="sm"
              buttonStyle="secondary"
              onClick={() => handleModifyParts(service)}
            >
              Modify parts
            </Button>
            <Button
              htmlId="approveAllPartsMenuServiceButton"
              size="sm"
              buttonStyle="secondary"
              disabled={
                !hasUnapprovedParts || isWithAdvisor || hasPartsWithOutUnitCost
              }
              title={
                isWithAdvisor
                  ? "Cannot approve parts while RO is still with advisor"
                  : hasPartsWithOutUnitCost
                  ? "Cannot approve parts while part has cost missing"
                  : hasUnapprovedParts
                  ? ""
                  : "All of this service's parts are already approved."
              }
              onClick={handleApproveAllParts}
            >
              Approve all parts
            </Button>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <div className="menuService" key={keyCompId} id={keyCompId}>
      <div className="menuService__title">
        <div className="sq-overflow-word">{service.serviceName || ""}</div>
      </div>

      <hr className="menuService__divider" />
      {laborHtml}
      {partsHeaderHtml}
      {partsContentHtml}

      {serviceDetailsSection}
      {servicelineBadges}
      {dealershipNotesHtml}
      {partsViewFooterHtml}
      {isCSR && !isPartsView ? (
        <div className="service-pay-type"> {technicianNamesHtml} </div>
      ) : null}
    </div>
  );
};

export default MenuService;
